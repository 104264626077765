import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionHead } from './section-head'
import { Button } from './button'
import { AssetImage } from './image'
import { pageMaxWidth } from '../styles/page-max-width'
import { CroppedText } from './cropped-text'

const Outer = styled.div`
  background-color: #F9F9F9;
`

const Inner = styled.div`
  padding-top: 4.375rem;
  padding-bottom: 2.25rem;
  position: relative;
  ${pageMaxWidth}
`


const ButtonContainer = styled.div`
  margin-top: -2rem;
  display: flex;
  justify-content: flex-end;
`

const List = styled.div`
  border-top: 1px solid #B3B3B3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 40rem){
    grid-template-columns: 100%;
  }
`

const Item = styled.a`
  padding: 4.375rem;
  border-right: 1px solid #B3B3B3;
  display: block;
  position: relative;
  overflow: clip;
  @media (max-width: 40rem){
    border-right: 0 none;
    border-bottom: 1px solid #B3B3B3;
  }
  & > * {
    z-index: 1;
    position: relative;
  }
`
const Bg = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity 1s, transform 1s;
  ${Item}:hover &{
    opacity: 1;
    transform: none;
  }
  @media (max-width: 40rem){
    display: none;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Description = styled(CroppedText)`
  font-size: 0.9375rem;
  line-height: 2.1333333333333333;
`

const Name = styled(CroppedText)`
  font-size: 1.125rem;
  line-height: 1.7777777777777777;
  margin-top: 1.25rem;
`

const ImageContainer = styled.div`
  margin-left: -4.375rem;
  margin-right: -4.375rem;
  margin-top: 1.5rem;
  position: relative;
  overflow: clip;
  img {
    display: block;
    width: 100%;
    height: auto;
    transition: opacity 0.8s, transform 0.8s;
  }
  picture:nth-child(1) img {
    ${Item}:hover & {
      opacity: 0;
      @media (max-width: 40rem){
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }
`

const Price = styled(CroppedText)`
  font-size: 0.875rem;
  line-height: 1.7142857142857142;
  margin-top: 1.5rem;
  text-align: center;
`

type ProductListProps = {

}
export const ProductList: FunctionComponent<ProductListProps> = () => {
  return <Outer>
    <Inner>
      <SectionHead>
        PRODUCTS
      </SectionHead>
      <ButtonContainer>
        <Button href="/shop/products/">
          View All
        </Button>
      </ButtonContainer>
    </Inner>
    <List>
      <Item href="/shop/products/ZC001">
        <Bg>
          <AssetImage src="product_01_hover.png" />
        </Bg>
        <Description>
          リンクルセラム
        </Description>
        <Name>
          APROS iO
        </Name>
        <ImageContainer>
          <AssetImage src="p1-1.png" />
        </ImageContainer>
        <Price>
          11,000円(税込)/30ml
        </Price>
      </Item>
      <Item href="/shop/products/ZZ001">
        <Bg>
          <AssetImage src="product_02_hover.png" />
        </Bg>
        <Description>
          ダニとりシート
        </Description>
        <Name>
          ダニとり酵母
        </Name>
        <ImageContainer>
          <AssetImage src="p2-1.png" />
        </ImageContainer>
        <Price>
          7,920円(税込)/3枚入
        </Price>
      </Item>
      <Item href="/shop/products/ZH001">
        <Bg>
          <AssetImage src="p3-2.png" />
        </Bg>
        <Description>
          サプリメント
        </Description>
        <Name>
          シボサポ
        </Name>
        <ImageContainer>
          <AssetImage src="p3-1.png" />
        </ImageContainer>
        <Price>
          8,640円(税込)/1袋
        </Price>
      </Item>
    </List>
  </Outer>
}